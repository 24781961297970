import React, {Fragment, lazy, Suspense} from "react";
import {CssBaseline, MuiThemeProvider} from "@material-ui/core";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import Pace from "./shared/components/Pace";
import withClearCache from "./ClearCache";

const LoggedInComponent = lazy(() => import("./logged_in/components/Main"));

const LoggedOutComponent = lazy(() => import("./logged_out/components/Main"));

const ClearCacheComponent = withClearCache(MainApp);

function MainApp() {
    return (
        <BrowserRouter>
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                <GlobalStyles/>
                <Pace color={theme.palette.primary.light}/>
                <Suspense fallback={<Fragment/>}>
                    <Switch>
                        <Route path="/c">
                            <LoggedInComponent/>
                        </Route>
                        <Route>
                            <LoggedOutComponent/>
                        </Route>
                    </Switch>
                </Suspense>
            </MuiThemeProvider>
        </BrowserRouter>
    );
}

function App() {
    return <ClearCacheComponent />;
}

export default App;
